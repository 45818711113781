import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('client', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchArchiveClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchArchiveClients', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    userStatus(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`userStatus/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`client/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else if (response.data.status == 'D') {
              response.data.status = 'deleted';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('permissions')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('client', clientData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`client/${clientData.id}`, clientData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
